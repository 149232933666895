import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    flex: "none",
    "& .MuiButtonBase-root": {
      width: "100%",
      height: 44,
      borderRadius: "100px",
      fontSize: 14,
      fontWeight: 600,
      textTransform: "none !important",
      fontFamily: "Montserrat",
      background: "var(--White, #FFF)",
      color: "var(--Primary-Blue---500, #006298)",
      "&:hover": {
        background: "var(--Primary-Blue---400, #118AC7)",
        color: "white !important",
      },
      "&:active": {
        background: "#075080",
        color: "white !important",
      },
      "&:focus": {
        background: "#006298",
        color: "white !important",
      },
    },
    "& .MuiButton-contained": {
      background: "#006298",
      color: "white",
    },
    "& .MuiButton-outlined": {
      border: "1px solid var(--Neutral-Gray---100, #DAE2EA)",
    },
  },
}));

export function CustomButton({
  element,
  variant,
  className,
  onClick,
  children,
  disabled,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        onClick={() => onClick(element)}
        variant={variant}
        className={className}
        disabled={disabled}
      >
        {element && element.title}
        {children}
      </Button>
    </div>
  );
}
